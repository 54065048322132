/* Style the form container */

/* Style the navigation bar */
nav {
  background-color: #333;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 10px;
}

nav a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

nav a:hover {
  background-color: #555;
}

form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Style the headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-top: 0;
}

/* Style the welcome message */
form p:first-of-type {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Style the list */
form ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

/* Style the list items */
form li {
  font-size: 16px;
  margin-bottom: 10px;
}

/* Style the address input */
form input[type="text"] {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

/* Style the submit button */
form button[type="submit"] {
  background-color: #18ed86;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}

/* Style the link */
form a {
  color: #18ed86;
}

/* Style the powered by message */
form p:last-of-type {
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

/* Add responsive styles */
@media only screen and (max-width: 500px) {
  form {
    width: 90%;
  }
}

/* Dark mode styles */
body {
  background-color: #222;
  color: #fff;
}

form {
  margin-top: 20px;
  background-color: #444;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

form li {
  color: #ccc;
}

form button[type="submit"] {
  text-align: center;
  background-color: #18ed86;
  color: #222;
}

form a {
  color: #18ed86;
}

form p:last-of-type {
  color: #ccc;
}

form p {
  text-align: center;
}
form ul {
  text-align: center;
}

form button[type="submit"] {
  display: block;
  margin: 0 auto;
}
